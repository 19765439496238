@font-face {
  font-family: 'KinfolkReg';
  src: local('KinfolkReg'), url(./assets/fonts/KinfolkReg.otf) format('opentype');
}

@font-face {
  font-family: 'ApothecarySerifSpaced';
  src: local('ApothecarySerifSpaced'), url(./assets/fonts/ApothecarySerifSpaced.otf) format('opentype');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}