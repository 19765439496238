.embla {
  position: relative;
  background-color: inherit;
  margin: 70px 40px 0px 40px;
}

.embla__viewport {
  overflow: hidden;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
  min-width: 100%;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}


.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 10px;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
  display: flex;
  align-items: center;
}



.embla__dot:after {
  background-color: #93857E;
  width: 100%;
  height: 10px;
  border-radius: 50% 50% 0 0;
  content: "";
}


.embla__dot.is-selected:after {
  background-color: #2E1103;
  opacity: 1;
}

h1{
  font-family: KinfolkReg;
  font-size: 26.8px;
  margin: 0;
  padding: 0
}


h3{
  font-family: KinfolkReg;
  font-size: 8.3px;
  margin-bottom: 10px;
  padding: 0
}

p{
  font-family: Iowan Old Style;
  font-size: 13px;
}